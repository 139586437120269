<div class="row">
<!--  <div *ngIf="!mobile" class="col-1"></div>-->
  <div class="col">
    <div class="row mt-3">
      <div class="col" style="text-align: center;">
        <img class="center-media__img" src="https://fmba.gov.ru/local/templates/main_new/img/logo.png">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="mx-5" orientation="vertical" style="text-align: center;">
          <li ngbNavItem="oc">
            <a [routerLink]="['/ocsfo']" class="btn btn-outline-info py-4" ngbNavLink (mouseover)="setActive('oc')">Окружной медицинский центр ФМБА России по СФО</a>
            <ng-template ngbNavContent>
              <h4>Окружной медицинский центр ФМБА России по СФО</h4>
              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/photo5461061809357304745_d6864a62f1.jpg" alt=""></p>
              <p>Согласно приказу руководителя Федерального медико-биологического агентства В.И. Скворцовой от 30 июля 2020 года ФСНКЦ ФМБА России получил статус Окружного медицинского центра (ОМЦ) ФМБА России в Сибирском федеральном округе.</p>
              <p>Являясь Окружным медцентром ФМБА России по СФО, ФСНКЦ ФМБА России обеспечивает координацию деятельности медицинских организаций и проводит анализ эффективности медицинской деятельности в Сибирском федеральном округе.</p>
            </ng-template>
          </li>
          <li class="mt-3" ngbNavItem="mes">
            <a ngbNavLink [routerLink]="['/main_extraordinary_specialists']" class="btn btn-outline-primary py-4" (mouseover)="setActive('mes')"><span>Главные внештатные специалисты по СФО</span></a>
            <ng-template ngbNavContent>
              <h4>Главные внештатные специалисты по СФО</h4>
              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/photo5461061809357304745_d6864a62f1.jpg" alt=""></p>
              <p>Согласно приказу руководителя Федерального медико-биологического агентства В.И. Скворцовой от 30 июля 2020 года ФСНКЦ ФМБА России получил статус Окружного медицинского центра (ОМЦ) ФМБА России в Сибирском федеральном округе.</p>
              <p>Являясь Окружным медцентром ФМБА России по СФО, ФСНКЦ ФМБА России обеспечивает координацию деятельности медицинских организаций и проводит анализ эффективности медицинской деятельности в Сибирском федеральном округе.</p>
            </ng-template>
          </li>
          <li class="mt-3" ngbNavItem="skc">
            <a [routerLink]="['/main']" class="btn btn-outline-primary py-4" ngbNavLink (mouseover)="setActive('skc')"><span>Медицинские услуги</span></a>
            <ng-template ngbNavContent>
              <h4>Сайт Федерального Сибирского научно-клинического центра ФМБА России</h4>
              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/photo5461061809357304744_5cb6b60808.jpg" alt=""></p>
              <p>На сайте представлена информация обо всех направлениях медицинской деятельности и услугах.</p>
              <p>ФСНКЦ ФМБА России оказывает первичную, специализированную, в том числе высокотехнологичную, скорую, паллиативную медицинскую помощь пациентам.</p>
              <p>В центре работают около 3 000 опытных специалистов, среди которых Заслуженные врачи РФ, профессора, доктора и кандидаты медицинских наук.</p>
            </ng-template>
          </li>
          <li class="mt-3" ngbNavItem="obr">
            <a ngbNavLink (click)="toObr()" class="btn btn-outline-primary py-4" (mouseover)="setActive('obr')">Учебно-образовательный центр</a>
            <ng-template ngbNavContent>
              <h4>Учебно-образовательный центр</h4>
              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/photo5461061809357304757_c7687a6f87.jpg" alt=""></p>
              <p>Информация о программах дополнительного профессионального образования на базе ФСНКЦ ФМБА России.</p>
              <p>Учебно-образовательный центр имеет лицензию на право осуществления образовательной деятельности и является одним из ведущих в сфере дополнительного профессионального образования.</p>
              <p>В нём трудятся Заслуженные врачи РФ, доктора и кандидаты медицинских наук, врачи высшей квалификационной категории.</p>
            </ng-template>
          </li>
          <li class="mt-3" ngbNavItem="innov">
            <a [routerLink]="['/centers/113']" class="btn btn-outline-primary py-4" ngbNavLink (mouseover)="setActive('innov')">Отдел науки и инноваций</a>
            <ng-template ngbNavContent>
              <h4>Отдел науки и инноваций</h4>
              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/api/uploads/photo5244671478560045387_a307a182d8.jpg" alt=""></p>
              <p>В 2016 году Федеральному Сибирскому научно-клиническому центру ФМБА России присвоен научный статус.<br>
                Специалисты проводят фундаментальные и прикладные научные исследования и внедряют в жизнь разработки в перспективных и инновационных областях медицины. Занимаются развитием нано-, био-, информационных, когнитивных, клеточных и биомедицинских технологий.</p>
                <p>На базе научно-клинического центра открыта молекулярно-генетическая лаборатория Сибирского федерального университета.<br>
                  Специалисты лаборатории проводят исследования, которые входят в генетический паспорт здоровья. Паспорт содержит подробное и индивидуальное описание факторов и степени риска развития серьезных заболеваний, а также рекомендации по их эффективной профилактике.</p>
                <p>В 2020 году на базе отдела науки и инноваций создан учёный совет.</p>
            </ng-template>
          </li>
<!--          <li class="mt-3" ngbNavItem="covid19">-->
<!--            <a [routerLink]="['/pages/354']" class="btn btn-outline-danger py-4" ngbNavLink (mouseover)="setActive('covid19')">COVID-19</a>-->
<!--            <ng-template ngbNavContent>-->
<!--              <h4>COVID-19</h4>-->
<!--              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/a2c952d6_14e1_4977_a2ab_ccf8ce3eac19_fb8ca633cf.jpeg" alt=""></p>-->
<!--              <p>Всё, что нужно знать о профилактике новой коронавирусной инфекции</p>-->
<!--              <ul>-->
<!--                <li>Вакцинация от COVID-19</li>-->
<!--                <li>Реабилитация пациентов, перенёсших COVID-19</li>-->
<!--                <li>Информация о новой коронавирусной инфекции (COVID-19) ФМБА России</li>-->
<!--              </ul>-->
<!--            </ng-template>-->
<!--          </li>-->
          <li class="mt-3" ngbNavItem="news">
            <a ngbNavLink [routerLink]="['/news']" class="btn btn-outline-primary py-4" (mouseover)="setActive('news')">Новости</a>
            <ng-template ngbNavContent>
<!--              <h4>Новости</h4>-->
<!--              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/photo5461061809357304757_c7687a6f87.jpg" alt=""></p>-->
<!--              <p>Информация о программах дополнительного профессионального образования на базе ФСНКЦ ФМБА России.</p>-->
<!--              <p>Учебно-образовательный центр имеет лицензию на право осуществления образовательной деятельности и является одним из ведущих в сфере дополнительного профессионального образования.</p>-->
<!--              <p>В нём трудятся Заслуженные врачи РФ, доктора и кандидаты медицинских наук, врачи высшей квалификационной категории.</p>-->
            </ng-template>
          </li>
<!--          <li class="mt-3" ngbNavItem="skc1">-->
<!--            <a ngbNavLink (click)="toEye()" class="btn btn-outline-dark py-4" (mouseover)="setActive('skc1')"><span>МЕДИЦИНСКИЕ УСЛУГИ<br>( ДЛЯ СЛАБОВИДЯЩИХ )</span></a>-->
<!--            <ng-template ngbNavContent>-->
<!--            </ng-template>-->
<!--          </li>-->
          <li class="mt-3" ngbNavItem="skc1">
            <a ngbNavLink [routerLink]="['/pages/369']" class="btn btn-outline-dark py-4" (mouseover)="setActive('skc1')"><span>Личный кабинет пациента</span></a>
            <ng-template ngbNavContent>
              <h4>Личный кабинет пациента</h4>
              <p><img class="direction_img_img" src="https://kb42.skc-fmba.ru/uploads/photo_lk.jpg" alt=""></p>
              <p>Уважаемые пациенты!</p>
              <p>Мы запустили для вас новый сервис.</p>
              <p>На нашем сайте начал работу личный кабинет, в котором вы можете увидеть данные своей медицинской карты и назначения, а также прочитать важные новости.</p>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="!mobile" class="col-lg-6">
    <div class="contact-card mr-6">
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
<!--  <div *ngIf="!mobile"  class="col-1"></div>-->
</div>
